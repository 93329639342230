.grid-item .portfolio-content {
    display: block;
    position: relative;
    overflow: hidden;
}

.grid-item .portfolio-content img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

.grid-item .portfolio-content h6 {
    font-size: 1rem;
    color: white;
    padding-bottom: 0.6rem;
}