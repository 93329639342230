.skills-container .skill-title {
    font-size: 1.4rem;
    font-weight: 400;
}
.skills-container .skill-bar {
    display: flex;
    align-items: center;
}
.skills-container .skill-bar .skill-progress {
    width: 100%;
}
.skills-container .skill-bar .skill-progress .progress {
    width: 100%;
    height: 0.5rem;
    background-color: red;
    margin-left: 1rem;
    background-color: rgba(3,129,255,.24705882352941178);
    position: relative;
}
.skills-container .skill-bar .skill-progress .progress .inner-pregress {
    position: absolute;
    background-color: #037fff;
    height: 100%;
    bottom: 0;
    left: 0;
}

.skillsContainer {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
    column-gap: 4rem;
    grid-row-gap: 3rem;
    row-gap: 3rem;
    margin: 4rem 0;
}