#reviews {
    display: grid;
    gap: 1.5rem;
    width: 100%;
}

.skillsContainer {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
    grid-column-gap: 4rem;
    column-gap: 4rem;
    grid-row-gap: 3rem;
    grid-row-gap: 3rem;
    row-gap: 3rem;
    margin: 4rem 0;
}