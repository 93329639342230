.dXWCyY {
    padding: 2rem 1rem;
    border-left: 6px solid #2e344e;
    background-color: #191d2b;
    position: relative;
    width: 100%;
}

.dXWCyY::after {
    content: "";
    position: absolute;
    left: 2rem;
    border-width: 0.8rem;
    top: 100%;
    border-style: solid;
    border-color: #191d2b transparent transparent #191d2b;
}

.dXWCyY p {
    padding: 1rem 0px;
}